import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <div className="py-4">
      <h1 className="title is-1 px-6">Page not found</h1>
      <p className="subtitle px-6">You just hit a route that doesn&#39;t exist...</p>
    </div>
  </Layout>
)

export default NotFoundPage
